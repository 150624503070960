import { Component } from '@angular/core';
import { AccountService } from '@app/services';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  shouldLogin = true;

  constructor(private accountService: AccountService) {
    const user = this.accountService.userValue;
    if (user) {
      this.shouldLogin = false;
    }
  }
}
