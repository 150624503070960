<div class="card-header">Dodawanie strzału</div>
<div class="card-content">
  <div>Wybierz zawody</div>
  <div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group" *ngIf="(competitions$ | async)?.data as competitions">
        <select name="competitionId" formControlName="competitionId">
          <option *ngFor="let competition of competitions" value="{{competition.ID}}">{{competition.Name}}</option>
        </select>
        <div *ngIf="submitted && f.competitionId.errors" class="invalid-feedback">
          <div *ngIf="f.competitionId.errors.required">Uzupelnij to pole</div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary">
          <span class="spinner-border spinner-border-sm mr-1"></span>
          Wybierz
        </button>
      </div>
    </form>
  </div>
  
</div>

