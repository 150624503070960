<div class="card-header">Edycja Bractwa</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <div class="form-group" *ngIf="(brotherhoods$ | async)?.data as brotherhoods">
        <label for="lastName">Wybierz bractwo</label>
        <select name="brotherhoodId" formControlName="brotherhoodId">
          <option *ngFor="let brotherhood of brotherhoods" value="{{brotherhood.ID}}">{{brotherhood.Name}}</option>
        </select>
        <div *ngIf="submitted && f.brotherhoodId.errors" class="invalid-feedback">
          <div *ngIf="f.brotherhoodId.errors.required">Uzupelnij to pole</div>
        </div>
      </div>

      <label for="name">Nowa nazwa bractwa</label>
      <input type="text" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors.required">Podaj nazwe bractwa</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Dodaj
      </button>
    </div>
  </form>
</div>

