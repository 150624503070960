import {Component} from '@angular/core';
import {DataService, SpinnerService} from '@app/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-display-competition',
  templateUrl: './display-competition.component.html',
  styleUrls: ['./display-competition.component.scss']
})
export class DisplayCompetitionComponent {
  competitions$ = this.dataService.getCompetitions().pipe(
    tap(() => this.spinnerService.closeSpinner())
  );

  constructor(private dataService: DataService,
    private spinnerService: SpinnerService) {
      this.spinnerService.openSpinner();
  }
}
