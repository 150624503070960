import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Shield } from '@app/models';

@Component({
  selector: 'app-shot-select-shield',
  templateUrl: './shot-select-shield.component.html',
  styleUrls: ['./shot-select-shield.component.scss']
})
export class ShotSelectShieldComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  shields: Shield[];
  competitionId: number;
  private componentDestroy$ = new Subject<void>();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router  ) {

  }

  ngOnInit() {
    this.competitionId = Number(this.route?.snapshot?.queryParams?.competitionId);

    this.form = this.formBuilder.group({
      shieldId: ['', Validators.required]
    });

    this.dataService.getShields(this.competitionId).pipe(takeUntil(this.componentDestroy$)).subscribe(shields => {
      this.shields = shields?.data;
    })
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.router.navigate(['/application/shot/add'], { queryParams: { competitionId: this.competitionId, shieldId: this.f.shieldId.value } });
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }
}
