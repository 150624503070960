<div class="card-header">Dodawanie strzału</div>
<div class="card-content">
  <div>Zawody: {{competitionName}}</div>
  <div>Tarcza: {{shieldName}}</div>

  <div>
    <div>Wybierz zawodnika</div>
    <div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group" *ngIf="(players$ | async)?.data as players">
          <select name="playerId" formControlName="playerId">
            <option *ngFor="let player of players"
                    value="{{player.ID}}">{{player.LastName}} {{player.FirstName}}</option>
          </select>
          <div *ngIf="submitted && f.playerId.errors" class="invalid-feedback">
            <div *ngIf="f.playerId.errors.required">Uzupelnij to pole</div>
          </div>
        </div>
        <div class="form-group">
          <label for="points">Punkty</label>
          <input type="number" formControlName="points" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.points.errors }"/>
          <div *ngIf="submitted && f.points.errors" class="invalid-feedback">
            <div *ngIf="f.points.errors.required">Podaj punkty</div>
          </div>
        </div>
        <div class="form-group">
          <label for="additionalPoints">Dodatkowe punkty</label>
          <input type="number" formControlName="additionalPoints" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.additionalPoints.errors }"/>
          <div *ngIf="submitted && f.additionalPoints.errors" class="invalid-feedback">
            <div *ngIf="f.additionalPoints.errors.required">Podaj punkty</div>
          </div>
        </div>
        <div *ngIf="multipleResults" class="form-group">
          <label for="points2">Punkty #2</label>
          <input type="number" formControlName="points2" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.points2.errors }"/>
          <div *ngIf="submitted && f.points2.errors" class="invalid-feedback">
            <div *ngIf="f.points2.errors.required">Podaj punkty</div>
          </div>
        </div>
        <div *ngIf="multipleResults" class="form-group">
          <label for="additionalPoints2">Dodatkowe punkty #2</label>
          <input type="number" formControlName="additionalPoints2" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.additionalPoints2.errors }"/>
          <div *ngIf="submitted && f.additionalPoints2.errors" class="invalid-feedback">
            <div *ngIf="f.additionalPoints2.errors.required">Podaj punkty</div>
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary">
            <span class="spinner-border spinner-border-sm mr-1"></span>
            Dodaj strzał
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
