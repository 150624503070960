<app-spinner *ngIf="isOpened"></app-spinner>
<div class="content">
    <div class="menu">
        <div class="submenu">BRACTWO</div> 
        <a class="link" [routerLink]="['/application/brotherhood/display']" [routerLinkActive]="'active'">wyświetl</a> 
        <a class="link" [routerLink]="['/application/brotherhood/add']" [routerLinkActive]="'active'">dodaj</a> 
        <a class="link" [routerLink]="['/application/brotherhood/edit']" [routerLinkActive]="'active'">edytuj</a> 
        <div class="submenu">ZAWODY</div> 
        <a class="link" [routerLink]="['/application/competition/display']" [routerLinkActive]="'active'">wyświetl</a> 
        <a class="link" [routerLink]="['/application/competition/add']" [routerLinkActive]="'active'">dodaj</a> 
        <a class="link" [routerLink]="['/application/competition/edit']" [routerLinkActive]="'active'">edytuj</a> 
        <div class="submenu">ZAWODNIK</div>
        <a class="link" [routerLink]="['/application/player/display']" [routerLinkActive]="'active'">wyświetl</a> 
        <a class="link" [routerLink]="['/application/player/add']" [routerLinkActive]="'active'">dodaj</a> 
        <a class="link" [routerLink]="['/application/player/edit']" [routerLinkActive]="'active'">edytuj</a> 
        <div class="submenu">TARCZA</div>
        <a class="link" [routerLink]="['/application/shield/display']" [routerLinkActive]="'active'">wyświetl</a> 
        <a class="link" [routerLink]="['/application/shield/add']" [routerLinkActive]="'active'">dodaj</a> 
        <a class="link" [routerLink]="['/application/shield/edit']" [routerLinkActive]="'active'">edytuj</a> 
        <div class="submenu">STRZAŁ</div>
        <a class="link" [routerLink]="['/application/shot/select-competition']" [routerLinkActive]="'active'">dodaj</a>
        <div class="submenu">WYNIKI</div>
        <a class="link" [routerLink]="['/application/results/select-competition']" [routerLinkActive]="'active'">wyświetl</a>   
        <a class="link" [routerLink]="['/application/results/select-data-to-edit-results']" [routerLinkActive]="'active'">edytuj</a>   
        <a class="link" [routerLink]="['/application/results/download']" [routerLinkActive]="'active'">pobierz</a>   
        <div class="submenu logout" (click)="logout()">WYLOGUJ</div>
    </div>
    <div class="card">
        <router-outlet></router-outlet>
    </div>
</div>