import { Component, EventEmitter } from '@angular/core';
import { AccountService } from '@app/services';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent {
  isOpened: boolean = false;

  constructor(private accountService: AccountService) {
    document.addEventListener('spinnerEvent', (e: any) => {
      this.isOpened = e.detail.isOpened; 
    });
  }

  logout() {
    this.accountService.logout();
  }
}
