<div class="card-header">Edycja wyników</div>
<div class="card-content">
  <div class="competitions">{{competitionName}}</div>
  <div class="content">
    <div class="shield">
      <div class="shield-name">{{shieldName}}</div>
      <table>
        <tr>
          <th>L.p.</th>
          <th>Zawodnik</th>
          <th>Bractwo</th>
          <th>Pkt</th>
          <th>Dod. pkt</th>
          <th *ngIf="multipleResults">Pkt #2</th>
          <th *ngIf="multipleResults">Dod. pkt #2</th>
          <th></th>
          <th></th>
        </tr>
        <tr *ngFor="let result of results; let i = index" class="{{(i + 1) <= 10 ? 'bold' : ''}}">
          <td>{{i + 1}}.</td>
          <td>{{result.FirstName}} {{result.LastName}}</td>
          <td>{{result.Brotherhood}}</td>
          <td class="points"><input type="number" [(ngModel)]="result.Points" /></td>
          <td class="points"><input step="0.1" type="number" [(ngModel)]="result.AdditionalPoints" /></td>
          <td *ngIf="multipleResults" class="points"><input type="number" [(ngModel)]="result.Points2" /></td>
          <td *ngIf="multipleResults" class="points"><input step="0.1" type="number" [(ngModel)]="result.AdditionalPoints2" /></td>
          <td><button (click)="edit(result.ID, result.Points, result.AdditionalPoints, result.Points2, result.AdditionalPoints2)">edytuj</button></td>
          <td><button (click)="delete(result.ID)">usuń</button></td>
        </tr>
      </table>
    </div>
  </div>
</div>
