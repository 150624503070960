<div class="competitions">{{competitionName}}</div>
<div class="content">
  <div class="shield">
    <div class="shield-name">{{shieldName1}}</div>
    <table>
      <tr>
        <th>L.p.</th>
        <th>Zawodnik</th>
        <th>Bractwo</th>
        <th>Pkt</th>
      </tr>
      <tr *ngFor="let result of results1; let i = index" class="{{(i + 1) <= 10 ? 'bold' : ''}}">
        <td>{{i + 1}}.</td>
        <td>{{result.FirstName}} {{result.LastName}}</td>
        <td>{{result.Brotherhood}}</td>
        <td *ngIf="multipleShots1" class="points">{{result.Points}} + {{result.Points2}} = <b>{{result.Points + result.Points2}}</b></td>
        <td *ngIf="!multipleShots1" class="points">{{result.Points}}</td>
<!--        <div *ngIf="condition; else elseBlock">Content to render when condition is true.</div>-->
<!--        <ng-template #elseBlock>Content to render when condition is false.</ng-template>-->
      </tr>
    </table>
  </div>
  <div class="shield">
    <div class="shield-name">{{shieldName2}}</div>
    <table>
      <tr>
        <th>L.p.</th>
        <th>Zawodnik</th>
        <th>Bractwo</th>
        <th>Pkt</th>
      </tr>
      <tr *ngFor="let result of results2; let i = index" class="{{(i + 1) <= 10 ? 'bold' : ''}}">
        <td>{{i + 1}}.</td>
        <td>{{result.FirstName}} {{result.LastName}}</td>
        <td>{{result.Brotherhood}}</td>
        <td *ngIf="multipleShots2" class="points">{{result.Points}} + {{result.Points2}} = <b>{{result.Points + result.Points2}}</b></td>
        <td *ngIf="!multipleShots2" class="points">{{result.Points}}</td>
      </tr>
    </table>
  </div>
  <div class="shield">
    <div class="shield-name">{{shieldName3}}</div>
    <table>
      <tr>
        <th>L.p.</th>
        <th>Zawodnik</th>
        <th>Bractwo</th>
        <th>Pkt</th>
      </tr>
      <tr *ngFor="let result of results3; let i = index" class="{{(i + 1) <= 10 ? 'bold' : ''}}">
        <td>{{i + 1}}.</td>
        <td>{{result.FirstName}} {{result.LastName}}</td>
        <td>{{result.Brotherhood}}</td>
        <td *ngIf="multipleShots3" class="points">{{result.Points}} + {{result.Points2}} = <b>{{result.Points + result.Points2}}</b></td>
        <td *ngIf="!multipleShots3" class="points">{{result.Points}}</td>
      </tr>
    </table>
  </div>
  <div class="shield">
    <div class="shield-name">{{shieldName4}}</div>
    <table>
      <tr>
        <th>L.p.</th>
        <th>Zawodnik</th>
        <th>Bractwo</th>
        <th>Pkt</th>
      </tr>
      <tr *ngFor="let result of results4; let i = index" class="{{(i + 1) <= 10 ? 'bold' : ''}}">
        <td>{{i + 1}}.</td>
        <td>{{result.FirstName}} {{result.LastName}}</td>
        <td>{{result.Brotherhood}}</td>
        <td *ngIf="multipleShots4" class="points">{{result.Points}} + {{result.Points2}} = <b>{{result.Points + result.Points2}}</b></td>
        <td *ngIf="!multipleShots4" class="points">{{result.Points}}</td>
      </tr>
    </table>
  </div>
</div>
