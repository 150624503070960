<div class="card-header">Lista tarcz</div>
<div class="card-content">
  <table>
    <tr>
      <th>L.p.</th>
      <th>Nazwa tarczy</th>
    </tr>
    <tr *ngFor="let shield of (shields$ | async)?.data; let i = index">
      <td>{{i + 1}}.</td>
      <td>{{shield.Name}}</td>
    </tr>
  </table>
</div>

