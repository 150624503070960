<div class="card-header">Dodawanie tarczy</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="name">Nazwa Tarczy</label>
      <input type="text" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors.required">Podaj nazwe</div>
      </div>
    </div>
    <div class="form-group" *ngIf="(competitions$ | async)?.data as competitions">
      <label for="competitionId">Wybierz zawody</label>
      <select name="competitionId" formControlName="competitionId">
        <option *ngFor="let competition of competitions" value="{{competition.ID}}">{{competition.Name}}</option>
      </select>
      <div *ngIf="submitted && f.competitionId.errors" class="invalid-feedback">
        <div *ngIf="f.competitionId.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <label for="multipleResults">Czy tarcza jest podwójna?</label>
      <select name="multipleResults" formControlName="multipleResults">
        <option value="true">Tak</option>
        <option value="false">Nie</option>
      </select>
      <div *ngIf="submitted && f.multipleResults.errors" class="invalid-feedback">
        <div *ngIf="f.multipleResults.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Dodaj
      </button>
    </div>
  </form>
</div>

