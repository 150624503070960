<div class="card-header">Lista zawodów</div>
<div class="card-content">
  <table>
    <tr>
      <th>L.p.</th>
      <th>Nazwa zawodów</th>
      <th>Data</th>
    </tr>
    <tr *ngFor="let competition of (competitions$ | async)?.data; let i = index">
      <td>{{i + 1}}.</td>
      <td>{{competition.Name}}</td>
      <td>{{competition.Date}}</td>
    </tr>
  </table>
</div>

