import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {DataService, SpinnerService} from '@app/services';

@Component({
  selector: 'app-add-shield',
  templateUrl: './add-shield.component.html',
  styleUrls: ['./add-shield.component.scss']
})
export class AddShieldComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  competitions$ = this.dataService.getCompetitions();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private spinnerService: SpinnerService) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      competitionId: ['', Validators.required],
      multipleResults: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.spinnerService.openSpinner();
    console.log(this.f.multipleResults.value);

    this.dataService.addShield(this.f.name.value, this.f.competitionId.value, JSON.parse(this.f.multipleResults.value))
      .pipe(first())
      .subscribe(
        data => {
          this.spinnerService.closeSpinner();
          window.alert('Dodano pomyślnie');
          location.reload();
        },
        error => {
          this.spinnerService.closeSpinner();
          window.alert('BŁĄD!');
        });
  }
}
