import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {DataService} from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-results-select-competition',
  templateUrl: './results-select-competition.component.html',
  styleUrls: ['./results-select-competition.component.scss']
})
export class ResultsSelectCompetitionComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  competitions$ = this.dataService.getCompetitions();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router  ) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      competitionId: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.router.navigate(['/application/results/select-shield'], { queryParams: { competitionId: this.f.competitionId.value } });
  }
}
