import {Component, OnDestroy, OnInit} from '@angular/core';
import {first, takeUntil} from 'rxjs/operators';
import {DataService, SpinnerService} from '@app/services';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-edit-results',
  templateUrl: './edit-results.component.html',
  styleUrls: ['./edit-results.component.scss']
})
export class EditResults implements OnInit, OnDestroy {
  results;
  competitionId: number;
  shieldId: number;
  shieldName: string;
  multipleResults = false;

  competitionName: string;

  private componentDestroy$ = new Subject<void>();



  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private spinnerService: SpinnerService) {

  }

  ngOnInit() {
    this.competitionId = Number(this.route?.snapshot?.queryParams?.competitionId);
    this.shieldId = Number(this.route?.snapshot?.queryParams?.shieldId);

    this.dataService.getShieldNameById(this.shieldId).pipe(takeUntil(this.componentDestroy$)).subscribe(shield => {
      this.shieldName = shield?.data[0]?.Name;
    });

    this.dataService.getCompetitionNameById(this.competitionId).pipe(takeUntil(this.componentDestroy$)).subscribe(competition => {
      this.competitionName = competition?.data[0]?.Name;
    });

    this.dataService.getResults(this.competitionId, this.shieldId).pipe(takeUntil(this.componentDestroy$)).subscribe(results => {
      this.results = results?.data;
      console.log(this.results);
    });

    this.dataService.getShieldMultipointsById(this.shieldId).pipe(takeUntil(this.componentDestroy$)).subscribe(shieldMulti => {
      if (shieldMulti?.data[0].MultipleResults === 1) {
        this.multipleResults = true;
      }
    });
  }

  edit(id: number, points: number, additionalPoints: number, points2: number, additionalPoints2: number) {
    this.spinnerService.openSpinner();
    this.dataService.editShot(id, points, additionalPoints, points2, additionalPoints2)
    .pipe(first())
    .subscribe(
      data => {
        this.spinnerService.closeSpinner();
        window.alert('Zedytowano pomyślnie');
        location.reload();
      },
      error => {
        this.spinnerService.closeSpinner();
        window.alert('BŁĄD!');
      });
  }

  delete(id: number) {
    this.dataService.deleteShot(id)
    .pipe(first())
    .subscribe(
      data => {
        window.alert('Usunięto pomyślnie');
        location.reload();
      },
      error => {
        window.alert('BŁĄD!');
      });
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }
}
