import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {DataService, SpinnerService} from '@app/services';

@Component({
  selector: 'app-add-competition',
  templateUrl: './add-competition.component.html',
  styleUrls: ['./add-competition.component.scss']
})
export class AddCompetitionComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private spinnerService: SpinnerService) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      date: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.spinnerService.openSpinner();

    this.dataService.addCompetition(this.f.name.value, this.f.date.value)
      .pipe(first())
      .subscribe(
        data => {
          this.spinnerService.closeSpinner();
          window.alert('Dodano pomyślnie');
          location.reload();
        },
        error => {
          this.spinnerService.closeSpinner();
          window.alert('BŁĄD!');
        });
  }
}
