import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {groupBy, takeUntil} from 'rxjs/operators';
import {DataService} from '@app/services';
import { Subject } from 'rxjs';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-download-results',
  templateUrl: './download-results.component.html',
  styleUrls: ['./download-results.component.scss']
})
export class DownloadResultsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  competitions = [];
  shields = [];
  isAlive = new Subject();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      competitionId: ['', Validators.required],
      withLicense: ['', Validators.required],
    });

    this.dataService.getCompetitions().pipe(takeUntil(this.isAlive)).subscribe(data => this.competitions = data?.data);
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.generateExcel(this.f.competitionId.value, this.f.withLicense.value);
  }

  generateExcel(competitionId, withLicense) {
    this.dataService.downloadResults(competitionId).pipe(takeUntil(this.isAlive)).subscribe(results => {
        const resultsByShield =  results?.data
        ?.filter(results => {
          if (withLicense === 'all') {
            return true;
          } else {
            return results.HasLicense === withLicense;
          }
        })
        ?.reduce(
          (entryMap, e) => entryMap.set(e.Name, [...entryMap.get(e.Name)||[], e]),
          new Map()
        );
        const competitionName = this.competitions?.find(comp => comp.ID?.toString() === competitionId)?.Name;    
        let workbook = new Workbook();

        for (let key of resultsByShield.keys()) {
          const data: [] = resultsByShield.get(key);
          let worksheet = workbook.addWorksheet(key);
          worksheet.columns = [
            { header: 'L.p.', key: 'id' },
            { header: 'Imię', key: 'firstName' },
            { header: 'Nazwisko', key: 'lastName' },
            { header: 'Bractwo', key: 'brotherhood' },
            { header: 'Punkty', key: 'points' },
            { header: 'Dod. punkty', key: 'additionalPoints' },
          ];

          data.forEach((value: any, index) => {
            worksheet.addRow({
                id: index + 1, 
                firstName: value.FirstName, 
                lastName: value.LastName, 
                brotherhood: value.Brotherhood,
                points: value.Points,
                additionalPoints: value.AdditionalPoints
              }, 'n');
          });

          worksheet.columns.forEach(function (column, i) {
            var maxLength = 0;
            column["eachCell"]({ includeEmpty: true }, function (cell) {
                var columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength ) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });
        }

        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, competitionName + '.xlsx');
        })
      });
  }

  ngOnDestroy() {
    this.isAlive.next();
    this.isAlive.complete();
  }
}
