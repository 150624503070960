import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Shield } from '@app/models';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-results-select-shield',
  templateUrl: './results-select-shield.component.html',
  styleUrls: ['./results-select-shield.component.scss']
})
export class ResultsSelectShieldComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  shields: Shield[];
  competitionId: number;
  private componentDestroy$ = new Subject<void>();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router  ) {

  }

  ngOnInit() {
    this.competitionId = Number(this.route?.snapshot?.queryParams?.competitionId);

    this.form = this.formBuilder.group({
      shieldId1: ['', Validators.required],
      shieldId2: ['', Validators.required],
      shieldId3: ['', Validators.required],
      shieldId4: ['', Validators.required]
    });

    this.dataService.getShields(this.competitionId).pipe(takeUntil(this.componentDestroy$)).subscribe(shields => {
      this.shields = shields?.data;
    });
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.form);

    if (this.form.invalid) {
      return;
    }
    window.open(`${environment.frontUrl}/results/display?competitionId=${this.competitionId}&shieldId1=${this.f.shieldId1.value}&shieldId2=${this.f.shieldId2.value}&shieldId3=${this.f.shieldId3.value}&shieldId4=${this.f.shieldId4.value}`, '_blank')
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }
}
