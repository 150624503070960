<div class="card-header">Lista zawodników</div>
<div class="card-content">
  <table>
    <tr>
      <th>L.p.</th>
      <th>Nazwisko</th>
      <th>Imię</th>
      <th>Bractwo</th>
      <th>Licencja</th>
    </tr>
    <tr *ngFor="let player of (players$ | async)?.data; let i = index">
      <td>{{i + 1}}.</td>
      <td>{{player.LastName}}</td>
      <td>{{player.FirstName}}</td>
      <td>{{player.Brotherhood}}</td>
      <td class="center">{{player.HasLicense === 'yes' ? 'tak' : 'nie'}}</td>
    </tr>
  </table>
</div>

