import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AddShotComponent } from './views/shot/add-shot/add-shot.component';
import { ApplicationComponent } from './views/application/application.component';
import { AddBrotherhoodComponent } from './views/brotherhood/add/add-brotherhood.component';
import { AddCompetitionComponent } from './views/competition/add/add-competition.component';
import { DisplayResults } from './views/results/display/display-results/display-results.component';
import { LoginComponent } from './views/login/login.component';
import { AddPlayerComponent } from './views/player/add/add-player.component';
import { ResultsSelectCompetitionComponent } from './views/results/display/results-select-competition/results-select-competition.component';
import { ResultsSelectShieldComponent } from './views/results/display/results-select-shield/results-select-shield.component';
import { AddShieldComponent } from './views/shield/add/add-shield.component';
import { ShotSelectCompetitionComponent } from './views/shot/shot-select-competition/shot-select-competition.component';
import { ShotSelectShieldComponent } from './views/shot/shot-select-shield/shot-select-shield.component';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { EditBrotherhoodComponent } from './views/brotherhood/edit/edit-brotherhood.component';
import { EditCompetitionComponent } from './views/competition/edit/edit-competition.component';
import { EditPlayerComponent } from './views/player/edit/edit-player.component';
import { EditShieldComponent } from './views/shield/edit/edit-shield.component';
import { SelectDataToEditResultsComponent } from './views/results/edit/select-data-to-edit-results/select-data-to-edit-results.component';
import { EditResults } from './views/results/edit/edit-results/edit-results.component';
import { DisplayBrotherhoodComponent } from './views/brotherhood/display/display-brotherhood.component';
import { DisplayCompetitionComponent } from './views/competition/display/display-competition.component';
import { DisplayPlayersComponent } from './views/player/display/display-players.component';
import { DisplayShieldsComponent } from './views/shield/display/display-shields.component';
import { DownloadResultsComponent } from './views/results/download/download-results.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'application',
    component: ApplicationComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', component: WelcomeComponent
      },
      {
        path: 'brotherhood', 
      children: [
        {path: 'add', component: AddBrotherhoodComponent},
        {path: 'edit', component: EditBrotherhoodComponent},
        {path: 'display', component: DisplayBrotherhoodComponent},
      ]
    },
      {
        path: 'player',
        children: [
          {path: 'add', component: AddPlayerComponent},
          {path: 'edit', component: EditPlayerComponent},
          {path: 'display', component: DisplayPlayersComponent},
        ]
      },
      {
        path: 'shot',
        children: [
          {path: 'add', component: AddShotComponent},
          {path: 'select-shield', component: ShotSelectShieldComponent},
          {path: 'select-competition', component: ShotSelectCompetitionComponent},
    
        ]
      },
      {
        path: 'shield',
        children: [
          {path: 'add', component: AddShieldComponent},
          {path: 'edit', component: EditShieldComponent},
          {path: 'display', component: DisplayShieldsComponent},
        ]
      },
      {
        path: 'competition',
        children: [
          {path: 'add', component: AddCompetitionComponent},
          {path: 'edit', component: EditCompetitionComponent},
          {path: 'display', component: DisplayCompetitionComponent},
        ]
      },
      {
        path: 'results',
        children: [
          {path: 'select-competition', component: ResultsSelectCompetitionComponent},
          {path: 'select-shield', component: ResultsSelectShieldComponent},
          {path: 'select-data-to-edit-results', component: SelectDataToEditResultsComponent},
          {path: 'edit', component: EditResults},
          {path: 'download', component: DownloadResultsComponent},
        ]
      },
    ]
  },
  {
    path: 'results',
    children: [
      {path: 'display', component: DisplayResults},
    ]
  },
  { path: '',   redirectTo: '/application', pathMatch: 'full' },
  { path: '**',   redirectTo: '/application', pathMatch: 'full' },

]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
