import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first, takeUntil} from 'rxjs/operators';
import {DataService, SpinnerService} from '@app/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-competition',
  templateUrl: './edit-competition.component.html',
  styleUrls: ['./edit-competition.component.scss']
})
export class EditCompetitionComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  competitions = [];
  isAlive = new Subject();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private spinnerService: SpinnerService) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      date: ['', Validators.required],
      competitionId: ['', Validators.required],
    });

    this.dataService.getCompetitions().pipe(takeUntil(this.isAlive)).subscribe(data => this.competitions = data?.data);

    this.form.controls['competitionId'].valueChanges.pipe(takeUntil(this.isAlive)).subscribe(value => {
      const updatedCompetition = this.competitions?.find(comp => comp.ID?.toString() === value);
  
      if (updatedCompetition) {
        this.form.patchValue({
          name: updatedCompetition.Name, 
          date: updatedCompetition.Date
        });
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.spinnerService.openSpinner();

    this.dataService.editCompetition(this.f.competitionId.value, this.f.name.value, this.f.date.value)
      .pipe(first(), takeUntil(this.isAlive))
      .subscribe(
        data => {
          this.spinnerService.closeSpinner();
          window.alert('Zedytowano pomyślnie');
          location.reload();
        },
        error => {
          this.spinnerService.closeSpinner();
          window.alert('BŁĄD!');
        });
  }

  ngOnDestroy() {
    this.isAlive.next();
    this.isAlive.complete();
  }
}
