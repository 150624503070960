import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SpinnerService {

    openSpinner() {
        document.dispatchEvent(this.spinnerOpenedEvent(true));
    }

    closeSpinner() {
        document.dispatchEvent(this.spinnerOpenedEvent(false));
    }

    spinnerOpenedEvent(isOpened: boolean) {
        return new CustomEvent('spinnerEvent', {
          detail: {
            isOpened
          }
        });
    }
}
