<div class="card-header">Wyświetlanie wyników</div>
<div class="card-content">
  <div>Wybierz tarcze</div>
  <div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <div>
          1: <select name="shieldId1" formControlName="shieldId1">
            <option *ngFor="let shield of shields" value="{{shield.ID}}">{{shield.Name}}</option>
          </select>
          <div *ngIf="submitted && f.shieldId1.errors" class="invalid-feedback">
            <div *ngIf="f.shieldId1.errors.required">Uzupelnij to pole</div>
          </div>
        </div>
        <div>
          2: <select name="shieldId2" formControlName="shieldId2">
            <option *ngFor="let shield of shields" value="{{shield.ID}}">{{shield.Name}}</option>
          </select>
          <div *ngIf="submitted && f.shieldId2.errors" class="invalid-feedback">
            <div *ngIf="f.shieldId2.errors.required">Uzupelnij to pole</div>
          </div>
        </div>
       <div>
        3: <select name="shieldId3" formControlName="shieldId3">
          <option *ngFor="let shield of shields" value="{{shield.ID}}">{{shield.Name}}</option>
        </select>
        <div *ngIf="submitted && f.shieldId3.errors" class="invalid-feedback">
          <div *ngIf="f.shieldId3.errors.required">Uzupelnij to pole</div>
        </div>
       </div>
       <div>
        4: <select name="shieldId4" formControlName="shieldId4">
          <option *ngFor="let shield of shields" value="{{shield.ID}}">{{shield.Name}}</option>
        </select>
        <div *ngIf="submitted && f.shieldId4.errors" class="invalid-feedback">
          <div *ngIf="f.shieldId4.errors.required">Uzupelnij to pole</div>
        </div>
       </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary">
          <span class="spinner-border spinner-border-sm mr-1"></span>
          Wybierz
        </button>
      </div>
    </form>
  </div>
  
</div>

