<div class="card-header">Pobieranie wyników</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group" *ngIf="competitions">
      <div>
        1. Wybierz zawody:
        <select name="competitionId" formControlName="competitionId">
          <option *ngFor="let competition of competitions" value="{{competition.ID}}">{{competition.Name}}</option>
        </select>
        <div *ngIf="submitted && f.competitionId.errors" class="invalid-feedback">
          <div *ngIf="f.competitionId.errors.required">Uzupelnij to pole</div>
        </div>
      </div>
      <div>
        2.Licencja:
        <select name="withLicense" formControlName="withLicense">
          <option value="all">wszyscy</option>
          <option value="yes">tylko z licencją</option>
          <option value="no">tylko bez licencji</option>
        </select>
        <div *ngIf="submitted && f.withLicense.errors" class="invalid-feedback">
          <div *ngIf="f.withLicense.errors.required">Uzupelnij to pole</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Pobierz
      </button>
    </div>
  </form>
</div>

