<div class="card-header">Edycja wyników</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group" *ngIf="competitions">
      1. Wybierz zawody:
      <select name="competitionId" formControlName="competitionId">
        <option *ngFor="let competition of competitions" value="{{competition.ID}}">{{competition.Name}}</option>
      </select>
      <div *ngIf="submitted && f.competitionId.errors" class="invalid-feedback">
        <div *ngIf="f.competitionId.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div>
      2. Wybierz tarczę:
      <select name="shieldId" formControlName="shieldId">
        <option *ngFor="let shield of shields" value="{{shield.ID}}">{{shield.Name}}</option>
      </select>
      <div *ngIf="submitted && f.shieldId.errors" class="invalid-feedback">
        <div *ngIf="f.shieldId.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Wybierz
      </button>
    </div>
  </form>
</div>

