<div class="card-header">Edycja tarczy</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    Wybierz tarczę:
    <select name="shieldId" formControlName="shieldId">
      <option *ngFor="let shield of shields" value="{{shield.ID}}">{{shield.Name}}</option>
    </select>
    <div *ngIf="submitted && f.shieldId.errors" class="invalid-feedback">
      <div *ngIf="f.shieldId.errors.required">Uzupelnij to pole</div>
    </div>

    <div class="form-group">
      <label for="name">Nowa nazwa Tarczy</label>
      <input type="text" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors.required">Podaj nazwe</div>
      </div>
    </div>
    <div class="form-group" *ngIf="(competitions$ | async)?.data as competitions">
      <label for="competitionId">Wybierz zawody, które będą przypisane do tarczy</label>
      <select name="competitionId" formControlName="competitionId">
        <option *ngFor="let competition of competitions" value="{{competition.ID}}">{{competition.Name}}</option>
      </select>
      <div *ngIf="submitted && f.competitionId.errors" class="invalid-feedback">
        <div *ngIf="f.competitionId.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Edytuj
      </button>
    </div>
  </form>
</div>

