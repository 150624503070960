import {Component} from '@angular/core';
import {DataService, SpinnerService} from '@app/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-display-shields',
  templateUrl: './display-shields.component.html',
  styleUrls: ['./display-shields.component.scss']
})
export class DisplayShieldsComponent {
  shields$ = this.dataService.getAllShields().pipe(
    tap(() => this.spinnerService.closeSpinner())
  );

  constructor(private dataService: DataService,
    private spinnerService: SpinnerService) {
      this.spinnerService.openSpinner();
  }
}
