import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first, takeUntil} from 'rxjs/operators';
import {DataService, SpinnerService} from '@app/services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-player',
  templateUrl: './edit-player.component.html',
  styleUrls: ['./edit-player.component.scss']
})
export class EditPlayerComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  brotherhoods$ = this.dataService.getBrotherhoods();
  players = [];
  isAlive = new Subject();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private spinnerService: SpinnerService) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      hasLicense: ['', Validators.required],
      brotherhoodId: ['', Validators.required],
      playerId: ['', Validators.required]
    });

    this.dataService.getPlayers().pipe(takeUntil(this.isAlive)).subscribe(data => this.players = data?.data);
    this.form.controls['playerId'].valueChanges.pipe(takeUntil(this.isAlive)).subscribe(value => {
      const updatedPlayer = this.players?.find(player => player.ID?.toString() === value);
  
      if (updatedPlayer) {
        this.form.patchValue({
          firstName: updatedPlayer.FirstName, 
          lastName: updatedPlayer.LastName,
          hasLicense: updatedPlayer.HasLicense,
          brotherhoodId: updatedPlayer.BrotherhoodID
        });
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.spinnerService.openSpinner();

    this.dataService.editPlayer(this.f.playerId.value, this.f.firstName.value, this.f.lastName.value, this.f.brotherhoodId.value, this.f.hasLicense.value)
      .pipe(first())
      .subscribe(
        data => {
          this.spinnerService.closeSpinner();
          window.alert('Zedytowano pomyślnie');
          location.reload();
        },
        error => {
          this.spinnerService.closeSpinner();
          window.alert('BŁĄD!');
        });
  }

  ngOnDestroy() {
    this.isAlive.next();
    this.isAlive.complete();
  }
}
