import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first, takeUntil} from 'rxjs/operators';
import {DataService} from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-data-to-edit-results',
  templateUrl: './select-data-to-edit-results.component.html',
  styleUrls: ['./select-data-to-edit-results.component.scss']
})
export class SelectDataToEditResultsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  competitions = [];
  shields = [];
  isAlive = new Subject();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router  ) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      competitionId: ['', Validators.required],
      shieldId: ['', Validators.required]
    });

    this.dataService.getCompetitions().pipe(takeUntil(this.isAlive)).subscribe(data => this.competitions = data?.data);
    this.form.controls['competitionId'].valueChanges.pipe(takeUntil(this.isAlive)).subscribe(value => {
      this.dataService.getShields(value).pipe(takeUntil(this.isAlive)).subscribe(shields => {
        this.shields = shields?.data;
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.router.navigate(['/application/results/edit'], { queryParams: { competitionId: this.f.competitionId.value, shieldId: this.f.shieldId.value } });
  }

  ngOnDestroy() {
    this.isAlive.next();
    this.isAlive.complete();
  }
}
