<div class="card-header">Dodawanie Bractwa</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="name">Nazwa bractwa</label>
      <input type="text" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors.required">Podaj nazwe bractwa</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Dodaj
      </button>
    </div>
  </form>
</div>
