<div class="card-header">Dodawanie zawodnika</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="firstName">Imię</label>
      <input type="text" formControlName="firstName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"/>
      <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
        <div *ngIf="f.firstName.errors.required">Podaj imię</div>
      </div>
    </div>
    <div class="form-group">
      <label for="lastName">Nazwisko</label>
      <input type="text" formControlName="lastName" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"/>
      <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
        <div *ngIf="f.lastName.errors.required">Podaj nazwisko</div>
      </div>
    </div>
    <div class="form-group" *ngIf="(brotherhoods$ | async)?.data as brotherhoods">
      <label for="lastName">Wybierz bractwo</label>
      <select name="brotherhoodId" formControlName="brotherhoodId">
        <option *ngFor="let brotherhood of brotherhoods" value="{{brotherhood.ID}}">{{brotherhood.Name}}</option>
      </select>
      <div *ngIf="submitted && f.brotherhoodId.errors" class="invalid-feedback">
        <div *ngIf="f.brotherhoodId.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <label for="lastName">Czy posiada licencję</label>
      <select name="hasLicense" formControlName="hasLicense">
        <option value="yes">tak</option>
        <option value="no">nie</option>
      </select>
      <div *ngIf="submitted && f.hasLicense.errors" class="invalid-feedback">
        <div *ngIf="f.hasLicense.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Dodaj
      </button>
    </div>
  </form>
</div>

