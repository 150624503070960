import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {first, takeUntil} from 'rxjs/operators';
import {DataService, SpinnerService} from '@app/services';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-shot',
  templateUrl: './add-shot.component.html',
  styleUrls: ['./add-shot.component.scss']
})
export class AddShotComponent implements OnInit, OnDestroy {
  form: FormGroup;
  submitted = false;
  // shields;
  competitionId: number;
  shieldId: number;
  shieldName: string;
  competitionName: string;
  players$ = this.dataService.getPlayers();
  multipleResults: boolean;

  private componentDestroy$ = new Subject<void>();

  get f() {
    return this.form.controls;
  }

  constructor(private dataService: DataService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private spinnerService: SpinnerService) {

  }

  ngOnInit() {
    this.competitionId = Number(this.route?.snapshot?.queryParams?.competitionId);
    this.shieldId = Number(this.route?.snapshot?.queryParams?.shieldId);

    this.form = this.formBuilder.group({
      playerId: ['', Validators.required],
      points: ['', Validators.required],
      additionalPoints:  ['0']
    });

    // this.dataService.getShields(this.competitionId).pipe(takeUntil(this.componentDestroy$)).subscribe(shields => {
    //   this.shields = shields?.data;
    //   console.log(this.shields);
    // });
    this.dataService.getShieldMultipointsById(this.shieldId).pipe(takeUntil(this.componentDestroy$)).subscribe(shieldMulti => {
      console.log(shieldMulti);
      if (shieldMulti?.data[0].MultipleResults === 1) {
        this.form.addControl('points2', new FormControl('', Validators.required));
        this.form.addControl('additionalPoints2', new FormControl('0'));
        this.multipleResults = true;
      } else {
        this.multipleResults = false;
      }
      console.log(this.form);
    });

    this.dataService.getShieldNameById(this.shieldId).pipe(takeUntil(this.componentDestroy$)).subscribe(shield => {
      this.shieldName = shield?.data[0]?.Name;
    });

    this.dataService.getCompetitionNameById(this.competitionId).pipe(takeUntil(this.componentDestroy$)).subscribe(competition => {
      this.competitionName = competition?.data[0]?.Name;
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      window.alert('Błąd walidacji!');
      return;
    }
    this.spinnerService.openSpinner();
    let points2 = 0;
    let additionalPoints2 = 0;
    if (this.multipleResults) {
      points2 = this.f.points2.value;
      additionalPoints2 = this.f.additionalPoints2.value;
    }

    this.dataService.addShot(this.shieldId, this.f.playerId.value, this.f.points.value, this.f.additionalPoints.value, points2, additionalPoints2)
    .pipe(first())
    .subscribe(
      data => {
        this.spinnerService.closeSpinner();
        // window.alert('Dodano pomyślnie');
        location.reload();
      },
      error => {
        this.spinnerService.closeSpinner();
        window.alert('BŁĄD!');
      });  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }
}
