import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { environment } from '@environments/environment';
import { Brotherhood, User } from '@app/models';
import { AccountService } from './account.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataService implements OnDestroy {
    public user: Observable<User>;
    private httpOptions;
    private login: string;
    private componentDestroy$ = new Subject<void>();

    constructor(private http: HttpClient, private accountService: AccountService) {
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            })
          };
          this.accountService.user.pipe(takeUntil(this.componentDestroy$)).subscribe((data: any) => {
            this.login = data?.data?.login;
          });
    }

    ngOnDestroy() {
        this.componentDestroy$.next();
        this.componentDestroy$.complete();
    }

    addBrotherhood(name: string) {
        return this.http.put<any>(`${environment.apiUrl}/brotherhood/add`,
        {...this.httpOptions, ...{
            login: this.login,
            name
        }});
    }

    editBrotherhood(id: number, name: string) {
        return this.http.post<any>(`${environment.apiUrl}/brotherhood/edit`,
        {...this.httpOptions, ...{
            login: this.login,
            id,
            name
        }});
    }

    addShot(shieldId: number, playerId: number, points: number, additionalPoints: number, points2: number= 0, additionalPoints2: number= 0) {
        return this.http.put<any>(`${environment.apiUrl}/shot/add`,
        {...this.httpOptions, ...{
            login: this.login,
            shieldId,
            playerId,
            points,
            additionalPoints,
            points2,
            additionalPoints2
        }});
    }

    getBrotherhoods() {
        return this.http.get<any>(`${environment.apiUrl}/brotherhood/get?login=${this.login}`);
    }

    getCompetitions() {
        return this.http.get<any>(`${environment.apiUrl}/competition/get?login=${this.login}`);
    }

    getShields(competitionId: number) {
        return this.http.get<any>(`${environment.apiUrl}/shield/get?login=${this.login}&competitionId=${competitionId}`);
    }

    getAllShields() {
        return this.http.get<any>(`${environment.apiUrl}/shield/getAll?login=${this.login}`);
    }

    getShieldNameById(shieldId: number) {
        return this.http.get<any>(`${environment.apiUrl}/shield/getName?login=${this.login}&shieldId=${shieldId}`);
    }

    getShieldMultipointsById(shieldId: number) : Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}/shield/getMulti?login=${this.login}&shieldId=${shieldId}`);
    }

    getCompetitionNameById(competitionId: number) {
        return this.http.get<any>(`${environment.apiUrl}/competition/getName?login=${this.login}&competitionId=${competitionId}`);
    }

    getPlayers() {
        return this.http.get<any>(`${environment.apiUrl}/player/get?login=${this.login}`);
    }

    getResults(competitionId: number, shieldId: number) {
        return this.http.get<any>(`${environment.apiUrl}/shot/getResults?login=${this.login}&competitionId=${competitionId}&shieldId=${shieldId}`);
    }

    downloadResults(competitionId: number) {
        return this.http.get<any>(`${environment.apiUrl}/shot/downloadResults?login=${this.login}&competitionId=${competitionId}`);
    }

    addCompetition(name: string, date: string) {
        return this.http.put<any>(`${environment.apiUrl}/competition/add`,
        {...this.httpOptions, ...{
            login: this.login,
            name,
            date
        }});
    }

    editCompetition(id:number, name: string, date: string) {
        return this.http.post<any>(`${environment.apiUrl}/competition/edit`,
        {...this.httpOptions, ...{
            login: this.login,
            id,
            name,
            date
        }});
    }

    addPlayer(firstName: string, lastName: string, brotherhoodId: number, hasLicense: string) {
        return this.http.put<any>(`${environment.apiUrl}/player/add`,
        {...this.httpOptions, ...{
            login: this.login,
            firstName,
            lastName,
            brotherhoodId,
            hasLicense
        }});
    }

    editPlayer(id: number, firstName: string, lastName: string, brotherhoodId: number, hasLicense: string) {
        return this.http.post<any>(`${environment.apiUrl}/player/edit`,
        {...this.httpOptions, ...{
            login: this.login,
            firstName,
            lastName,
            brotherhoodId,
            hasLicense,
            id
        }});
    }

    addShield(name: string, competitionId: number, multipleResults: boolean) {
        console.log(competitionId, multipleResults);
        return this.http.put<any>(`${environment.apiUrl}/shield/add`,
        {...this.httpOptions, ...{
            login: this.login,
            name,
            competitionId,
            multipleResults,
        }});
    }

    editShield(id: number, name: string, competitionId: number) {
        return this.http.post<any>(`${environment.apiUrl}/shield/edit`,
        {...this.httpOptions, ...{
            login: this.login,
            name,
            competitionId,
            id
        }});
    }

    editShot(id: number, points: number, additionalPoints: number, points2: number, additionalPoints2: number) {
        return this.http.post<any>(`${environment.apiUrl}/shot/editPoints`,
        {...this.httpOptions, ...{
            additionalPoints,
            points,
            additionalPoints2,
            points2,
            id
        }});
    }

    deleteShot(id: number) {
        return this.http.delete<any>(`${environment.apiUrl}/shot/delete`,
        {...this.httpOptions, body: {
            id
        }});
    }
}
