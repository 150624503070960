<div class="card-header">Edycja zawodów</div>
<div class="card-content">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group" *ngIf="competitions">
      <label for="competitionId">Wybierz zawody</label>
      <select name="competitionId" formControlName="competitionId">
        <option *ngFor="let competition of competitions" value="{{competition.ID}}">{{competition.Name}}</option>
      </select>
      <div *ngIf="submitted && f.competitionId.errors" class="invalid-feedback">
        <div *ngIf="f.competitionId.errors.required">Uzupelnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <label for="name">Nowa nazwa zawodów</label>
      <input type="text" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors.required">Podaj nazwe zawodów</div>
      </div>
    </div>
    <div class="form-group">
      <label for="date">Nowa data zawodów</label>
      <input type="text" formControlName="date" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.date.errors }"/>
      <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
        <div *ngIf="f.date.errors.required">Podaj date zawodów</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Dodaj
      </button>
    </div>
  </form>
</div>

