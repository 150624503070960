import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '@app/services';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import $ from 'jquery';

@Component({
  selector: 'app-display-results',
  templateUrl: './display-results.component.html',
  styleUrls: ['./display-results.component.scss']
})
export class DisplayResults implements OnInit, OnDestroy, AfterViewInit {
  results1;
  results2;
  results3;
  results4;

  competitionId: number;
  shieldId1: number;
  shieldId2: number;
  shieldId3: number;
  shieldId4: number;

  shieldName1: string;
  shieldName2: string;
  shieldName3: string;
  shieldName4: string;

  multipleShots1: boolean;
  multipleShots2: boolean;
  multipleShots3: boolean;
  multipleShots4: boolean;

  competitionName: string;

  private componentDestroy$ = new Subject<void>();


  constructor(private dataService: DataService,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.competitionId = Number(this.route?.snapshot?.queryParams?.competitionId);
    this.shieldId1 = Number(this.route?.snapshot?.queryParams?.shieldId1);
    this.shieldId2 = Number(this.route?.snapshot?.queryParams?.shieldId2);
    this.shieldId3 = Number(this.route?.snapshot?.queryParams?.shieldId3);
    this.shieldId4 = Number(this.route?.snapshot?.queryParams?.shieldId4);

    this.dataService.getShieldNameById(this.shieldId1).pipe(takeUntil(this.componentDestroy$)).subscribe(shield => {
      this.shieldName1 = shield?.data[0]?.Name;
    });
    this.dataService.getShieldNameById(this.shieldId2).pipe(takeUntil(this.componentDestroy$)).subscribe(shield => {
      this.shieldName2 = shield?.data[0]?.Name;
    });
    this.dataService.getShieldNameById(this.shieldId3).pipe(takeUntil(this.componentDestroy$)).subscribe(shield => {
      this.shieldName3 = shield?.data[0]?.Name;
    });
    this.dataService.getShieldNameById(this.shieldId4).pipe(takeUntil(this.componentDestroy$)).subscribe(shield => {
      this.shieldName4 = shield?.data[0]?.Name;
    });

    this.dataService.getShieldMultipointsById(this.shieldId1).pipe(takeUntil(this.componentDestroy$)).subscribe(multipleShots => {
      this.multipleShots1 = multipleShots?.data[0]?.MultipleResults;
      console.log('multi1=' + this.multipleShots1);
    });

    this.dataService.getShieldMultipointsById(this.shieldId2).pipe(takeUntil(this.componentDestroy$)).subscribe(multipleShots => {
      this.multipleShots2 = multipleShots?.data[0]?.MultipleResults;
      console.log('multi2=' + this.multipleShots2);
    });

    this.dataService.getShieldMultipointsById(this.shieldId3).pipe(takeUntil(this.componentDestroy$)).subscribe(multipleShots => {
      this.multipleShots3 = multipleShots?.data[0]?.MultipleResults;
    });

    this.dataService.getShieldMultipointsById(this.shieldId4).pipe(takeUntil(this.componentDestroy$)).subscribe(multipleShots => {
      this.multipleShots4 = multipleShots?.data[0]?.MultipleResults;
    });

    this.dataService.getCompetitionNameById(this.competitionId).pipe(takeUntil(this.componentDestroy$)).subscribe(competition => {
      this.competitionName = competition?.data[0]?.Name;
    });

    this.dataService.getResults(this.competitionId, this.shieldId1).pipe(takeUntil(this.componentDestroy$)).subscribe(results => {
      this.results1 = results?.data;
    });
    this.dataService.getResults(this.competitionId, this.shieldId2).pipe(takeUntil(this.componentDestroy$)).subscribe(results => {
      this.results2 = results?.data;
    });
    this.dataService.getResults(this.competitionId, this.shieldId3).pipe(takeUntil(this.componentDestroy$)).subscribe(results => {
      this.results3 = results?.data;
    });
    this.dataService.getResults(this.competitionId, this.shieldId4).pipe(takeUntil(this.componentDestroy$)).subscribe(results => {
      this.results4 = results?.data;
    });
  }

  ngAfterViewInit() {
    if (!this.detectMob()) {
      setTimeout(() => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        $('html, body').animate({scrollTop:document.body.scrollHeight}, 30000, 'linear', () => {
          setTimeout(() => {
            $('html, body').animate({scrollTop: 0}, 25000, 'linear', () => {
              setTimeout(() => {
                location.reload();
              }, 4000);
            });
          }, 3000);
        });
      }, 4000);
     }
  }

  detectMob() {
    return ( ( window.innerWidth <= 800 ) && ( window.innerHeight <= 600 ) );
  }

  ngOnDestroy() {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }
}
