<div class="card-header">Logowanie</div>
<div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="username">Login </label>
      <input type="text" formControlName="username" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
      <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
        <div *ngIf="f.username.errors.required">Uzupełnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <label for="password">Hasło </label>
      <input type="password" formControlName="password" class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">Uzupełnij to pole</div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-primary">
        <span class="spinner-border spinner-border-sm mr-1"></span>
        Zaloguj
      </button>
    </div>
  </form>
</div>
