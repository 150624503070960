import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { WelcomeComponent } from './views/welcome/welcome.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { ApplicationComponent } from './views/application/application.component';
import { AddBrotherhoodComponent } from './views/brotherhood/add/add-brotherhood.component';
import { AddCompetitionComponent } from './views/competition/add/add-competition.component';
import { AddPlayerComponent } from './views/player/add/add-player.component';
import { AddShieldComponent } from './views/shield/add/add-shield.component';
import { ShotSelectCompetitionComponent } from './views/shot/shot-select-competition/shot-select-competition.component';
import { ShotSelectShieldComponent } from './views/shot/shot-select-shield/shot-select-shield.component';
import { AddShotComponent } from './views/shot/add-shot/add-shot.component';
import { ResultsSelectCompetitionComponent } from './views/results/display/results-select-competition/results-select-competition.component';
import { ResultsSelectShieldComponent } from './views/results/display/results-select-shield/results-select-shield.component';
import { DisplayResults } from './views/results/display/display-results/display-results.component';
import { EditBrotherhoodComponent } from './views/brotherhood/edit/edit-brotherhood.component';
import { EditCompetitionComponent } from './views/competition/edit/edit-competition.component';
import { EditPlayerComponent } from './views/player/edit/edit-player.component';
import { EditShieldComponent } from './views/shield/edit/edit-shield.component';
import { SelectDataToEditResultsComponent } from './views/results/edit/select-data-to-edit-results/select-data-to-edit-results.component';
import { EditResults } from './views/results/edit/edit-results/edit-results.component';
import { DisplayBrotherhoodComponent } from './views/brotherhood/display/display-brotherhood.component';
import { DisplayCompetitionComponent } from './views/competition/display/display-competition.component';
import { DisplayPlayersComponent } from './views/player/display/display-players.component';
import { DisplayShieldsComponent } from './views/shield/display/display-shields.component';
import { DownloadResultsComponent } from './views/results/download/download-results.component';
import { SpinnerComponent } from './views/spinner/spinner.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WelcomeComponent,
    ApplicationComponent,
    AddBrotherhoodComponent,
    EditBrotherhoodComponent,
    DisplayBrotherhoodComponent,
    AddCompetitionComponent,
    EditCompetitionComponent,
    DisplayCompetitionComponent,
    AddPlayerComponent,
    EditPlayerComponent,
    DisplayPlayersComponent,
    AddShieldComponent,
    DisplayShieldsComponent,
    EditShieldComponent,
    AddShotComponent,
    ShotSelectCompetitionComponent,
    ShotSelectShieldComponent,
    ResultsSelectCompetitionComponent,
    ResultsSelectShieldComponent,
    DisplayResults,
    SelectDataToEditResultsComponent,
    EditResults,
    DownloadResultsComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
